const videos = document.querySelectorAll('video')
for (let i = 0; i < videos.length; i++) {
  videos[i].playbackRate = 2.0
}

var myCarousel = document.querySelector('#myCarousel')
var carousel = new bootstrap.Carousel(myCarousel, {
  interval: 2000,
  wrap: false,
})

